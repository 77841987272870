import { Calendar } from '@fullcalendar/core';
import { Grid, Typography } from '@mui/material';
import { InputTextarea } from 'primereact/inputtextarea';
import React from 'react';

import { CheckboxField, InputField, SelectField } from './formFields';
import DatePicker from './formFields/DatePicker';
import { useTranslation } from 'react-i18next';


export default function PaxDataForm(props) {
  const { t, i18n } = useTranslation();

  let {
    formField: {
      firstname,
      lastname,
      identification,
      bloodType,
      bloodFactor,
      address,
      birthday,
      cellphone,
      city,
      email,
      tutorAddress,
      tutorCellphone,
      tutorFirstname,
      tutorLastname,
      observations
    }
  } = props;

  //move observations field to last position in array por visual purposes
  const formAttributes = Object.values(props.formField)
  formAttributes.push(formAttributes.splice(formAttributes.indexOf(formAttributes.find(it => it.name === 'observations')), 1)[0]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {formAttributes.map((obj, idx) => {
          switch (obj.type) {
            case 'text':
              return (
                obj.name === 'bloodFactor' && i18n.language === 'esCH' ?
                  <Grid key={idx} item xs={12}>
                  </Grid> :
                  <Grid key={idx} item xs={12} sm={obj.name === 'observations' ? 12 : 6}>
                    <InputField name={obj.name} label={obj.label} disabled={idx <= 2} fullWidth />
                  </Grid>
              )
            case 'date':
              return (
                <Grid key={idx} item xs={12} sm={6}>
                  <DatePicker name={obj.name} label={obj.label} fullWidth />
                </Grid>
              )
          }

        })}
      </Grid>
    </React.Fragment>
  );
}