import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const login = (email, password, context) => {
  return axios
    .post(API_URL + "/users/login",
      {
        email,
        password,
      })
    .then((response) => {
      if (response.data.tokens) {
        console.log(response.data)
        context.changeUser(response.data.user)
        localStorage.setItem('token', response.data.tokens.accessToken)
        localStorage.setItem('refresh-token', response.data.tokens.refreshToken)
        localStorage.setItem('token-init-date', new Date().getTime())
        localStorage.setItem('tenant-id', response.data.user.tenantId)
      }

      return response.data
    })
}

const whoAmI = (context) => {
  return axios
    .get(API_URL + "/whoAmI", {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then((response) => {
      if (response.data) {
        context.changeUser(response.data)
      }
      return response
    })
}

const loggedStartChecking = () => {
  const actualToken = localStorage.getItem('refresh-token') ? localStorage.getItem('refresh-token') : ''
  axios.post(`${API_URL}/refresh`, { refreshToken: actualToken }).then((resp) => {
    if (resp) {
      localStorage.setItem('token', resp.data.accessToken)
      localStorage.setItem('token-init-date', new Date().getTime())
      console.log(resp)
      /* dispatch(login({
          firstname: localStorage.getItem('firstname'),
          lastname: localStorage.getItem('lastname'),
          email: localStorage.getItem('email')
      })) */
    } else {
      logout()
    }
  }, (error) => {
    console.log(error.data)
    logout()
  })

}

const logout = () => {
  localStorage.clear()
  window.location.reload()
}

const exportedMethods = {
  login,
  whoAmI,
  logout,
  loggedStartChecking
}

export default exportedMethods