import * as Yup from 'yup';
import medicalRecordFormModel from './medicalRecordFormModel';
const {
    formField: {
        firstname,
        lastname,
        identification,
        bloodType,
        bloodFactor,
        address,
        birthday,
        cellphone,
        city,
        email,
        tutorAddress,
        tutorCellphone,
        tutorFirstname,
        tutorLastname,
        observations,
        acceptAndConfirm,
    }
} = medicalRecordFormModel;

export default [
    Yup.object().shape({
        [firstname.name]: Yup.string().required(`${firstname.requiredErrorMsg}`),
        [lastname.name]: Yup.string().required(`${lastname.requiredErrorMsg}`),
        [identification.name]: Yup.string().required(`${identification.requiredErrorMsg}`),
        [bloodType.name]: Yup.string().required(`${bloodType.requiredErrorMsg}`),
       // [bloodFactor.name]: Yup.string().required(`${bloodFactor.requiredErrorMsg}`),
        [address.name]: Yup.string().required(`${address.requiredErrorMsg}`),
        [birthday.name]: Yup.string().required(`${birthday.requiredErrorMsg}`),
        [cellphone.name]: Yup.string().required(`${cellphone.requiredErrorMsg}`),
        [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
        [email.name]: Yup.string().email(`${email.invalidErrorMsg}`).required(`${email.requiredErrorMsg}`),
        [tutorAddress.name]: Yup.string().required(`${tutorAddress.requiredErrorMsg}`),
        [tutorCellphone.name]: Yup.string().required(`${tutorCellphone.requiredErrorMsg}`),
        [tutorFirstname.name]: Yup.string().required(`${tutorFirstname.requiredErrorMsg}`),
        [tutorLastname.name]: Yup.string().required(`${tutorLastname.requiredErrorMsg}`),
    }),, Yup.object().shape({
        [acceptAndConfirm.name]: Yup.boolean().oneOf([true], `${acceptAndConfirm.requiredErrorMsg}`),
    }),
];