import React from 'react'
import FullScreenLoader from './styles/FullScreenLoader.module.scss'
import logo from 'assets/images/ab.png'

export const LogoAccesBand = ({ ...props }) => {

    const size = (props.size + 'px')

    return (
        <div style={{ textAlign: 'center' }}>
            <br />

            <span style={{ fontFamily: 'SF', fontWeight: '800', color: '#000000', fontSize: 18 }}>ACCESS</span><span style={{ fontFamily: 'SF', fontWeight: '900', color: '#229dd1', fontSize: 18 }}>BAND
                <br />
                <img style={{ marginTop:9, maxWidth: '70px' }} src={logo} alt="IMG" />
            </span>
        </div>
    )
}
