import logo from 'assets/images/ab.png'
import React from 'react'
import { Button } from 'primereact/button'
import { Tooltip } from '@mui/material'
import { accessBandAPI } from "api/access-band-api"
import AuthService from 'api/Auth'
import { Dialog } from 'primereact/dialog'

export const SocialHeader = () => {
    const evento = {
        id: localStorage.getItem('eventId'),
        code: localStorage.getItem('eventCode')
    }

    const [zipWait, setZipWait] = React.useState(false)
    const [modal, setModal] = React.useState(false)

    const downloadZip = async () => {
        setZipWait(true)
        console.log(evento)
        try {
            const eventcode = evento.code.split("_")[1]
            const result = await accessBandAPI.files.downloadZip({ id: evento.id, code: eventcode })
            if (result.status === 404) {
                alert('Archivo no encontrado')
            }
            else {
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', evento.code + '.zip'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        } catch (err) {
            alert('Todavía no hemos generado el archivo de fotos, consulta a la agencia!')
        }
        setZipWait(false)
    }

    const styles = {
        core: {
            display: 'flex',
            height: '64px',
            position: 'fixed',
            width: '100%',
            backgroundColor: 'white',
            bordeBottom: '1px solid rgba(var(--b6a,219,219,219),1)',
            zIndex: '3',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: '0px 50px',
            boxSizing: 'border-box'
        },
        shrink: {
            height: '50px'
        },
        navigationIcons: {
            display: 'flex',
        },
        navigationLink: {
            marginLeft: '30px',
            color: 'black',
            textDecoration: 'none',
            fontSize: '22px'
        }
    }

    const logout = async () => {
        AuthService.logout()
    }

    const hideModal = async () => {
        setModal(false)
    }

    const showModal = async () => {
        setModal(true)
    }

    const logoutButtons = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-success" onClick={hideModal} />
            <Button label="Salir" icon="pi pi-check" className="p-button-success" onClick={logout} />
        </>
    )

    return (
        <div style={styles.core}>
            <img style={{ marginTop: 9, maxWidth: '50px' }} src={logo} alt="IMG" />
            <span style={{fontWeight:600}}>{evento.code}</span>

            <div style={styles.navigationIcons}>
                <a href='#' style={styles.navigationLink}>
                    <i className="pi pi-compass" style={{ 'fontSize': '1em' }} />
                </a>

                {!zipWait ?
                    <a href='#' style={styles.navigationLink} onClick={downloadZip}>
                        <Tooltip title="Bajar fotos" placement="top">
                            <i className="pi pi-images" style={{ 'fontSize': '1em' }} />
                        </Tooltip>
                    </a>
                    : <div style={styles.navigationLink}>...</div>
                }

                <a href='#' style={styles.navigationLink} onClick={() => showModal()}>
                    <i className="pi pi-sign-out" style={{ 'fontSize': '1em' }} />
                </a>
            </div>
            <Dialog baseZIndex={1000} visible={modal} style={{ width: '450px' }} header="Confirmar Abandonar" modal
                footer={logoutButtons} onHide={modal} >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span>Seguro desea abandonar el Evento?</span>
                </div>
            </Dialog>
        </div>
    )
}
