import React from 'react'
import { InputText } from "primereact/inputtext"
import { Button } from 'primereact/button'
import 'layout/sass/form.scss'
import { useFormik } from "formik"
import AppContext from 'components/AppContext'
import AuthServices from 'api/Auth'
import { accessBandAPI } from 'api/access-band-api'

var classNames = require('classnames')

export const LoginScreen = () => {
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const context = React.useContext(AppContext)

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validate: (data) => {
            const errors = {}

            if (!data.username) {
                errors.username = 'Introducir nombre de usuario.'
            }

            if (!data.password) {
                errors.password = 'Introducir contraseña.'
            }

            return errors
        },
        onSubmit: (data) => {
            setError(false)
            setLoading(true)
            login(data.username, data.password)
            formik.resetForm()
        }
    })

    const login = (email, password) => {
        AuthServices.login(email, password, context).then((response) => {
            if (response) {
                accessBandAPI.configuration.getConfigurations().then((response) => {
                    localStorage.setItem('color1', response.data.color1)
                    localStorage.setItem('color2', response.data.color2)
                    localStorage.setItem('logo', response.data.logo)
                    localStorage.setItem('description', response.data.description)
                    localStorage.setItem('medicalFooter', response.data.medicalFooter)
                    localStorage.setItem('terms', response.data.terms)
                    localStorage.setItem('medicalHeader', response.data.medicalHeader)
                });
            }
            window.location.reload()
        },
            (error) => {
                setLoading(false)
                setError(true)
            })
    }

    const isFormFieldValid = (field) => !!(formik.touched[field] && formik.errors[field])
    const getFormErrorMessage = (field) => {
        return isFormFieldValid(field) && <small className="p-error">{formik.errors[field]}</small>
    }

    return (

        <div>
            <span style={{ fontWeight: '400', fontSize: '20px', marginTop: '25px', color: '#E53935', marginBottom: '25px' }}>Ingresa las credenciales</span>

            <form onSubmit={formik.handleSubmit} className="p-fluid" style={{ padding: '20px' }}>

                <div className="p-field">
                    <span className="p-float-label">
                        <InputText placeholder="Nombre de usuario" type="text" id="username" name="username" value={formik.values.username} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('username') })} />
                    </span>
                    {getFormErrorMessage('username')}
                </div>

                <br />

                <div className="p-field">
                    <span className="p-float-label">
                        <InputText placeholder="Contraseña" type='password' id="password" name="password" value={formik.values.password} onChange={formik.handleChange} toggleMask
                            className={classNames({ 'p-invalid': isFormFieldValid('password') })} />
                    </span>
                    {getFormErrorMessage('password')}
                </div>

                <div>
                    {error && <span style={{ color: 'Red' }}>Credenciales incorrectas</span>}
                </div>

                <Button type="submit" label="Ingresar" style={{ borderRadius: 33, backgroundColor: '#F4511E', padding: 10, fontSize: 16, border: 'none', width: 230, marginTop: '10px' }} />
            </form>
        </div>
    )
}
