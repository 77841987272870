import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';

export const FullScreenLoader = () => {

    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center'
        }}>
            <ProgressSpinner />
        </div>
    )
}
