import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import React, { useRef, useState } from 'react'
import VerificationInput from 'react-verification-input'
import { Button } from 'primereact/button'
import Styles from "./WelcomeScreen.module.scss"
import { accessBandAPI } from 'api/access-band-api'
import AppContext from 'components/AppContext'


export const SelectEventScreen = () => {
    const toast = useRef(null)
    const [code, setCode] = useState('')
    const [tenant, setTenant] = useState('')
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const context = React.useContext(AppContext)


    const handleSearchEvent = (event) => {
        event.preventDefault()
        setError(false)
        let convertedTenant = tenant.toLowerCase().replace(/[\W_]+/g, '')
        socialLogin(convertedTenant, code)
    }

    const socialLogin = (tenant, code) => {
        setLoading(true)
        accessBandAPI.events.getByEventCode(tenant, code).then((response) => {
            console.log(response)
            if (response.status === 200) {
                localStorage.setItem('eventCode', response.data.code)
                let result = response.data.code.split("_")
                localStorage.setItem('tenant-id', result[0])
                localStorage.setItem('eventId', response.data.id)
                window.location.reload()
            }

        },
            (error) => {
                setLoading(false)
                setError(true)
                //TODO: handle error
            })
    }

    return (
        <div>
            <Toast ref={toast} />
            <form onSubmit={handleSearchEvent}>
                <div className={Styles.eventCodeContainer}>
                    <span style={{ fontWeight: '400', fontSize: '20px', marginTop: '15px', color:'#E53935', marginBottom: '15px' }}>Ingrese el nombre de su agencia</span>

                    <InputText type="text" value={tenant} onInput={(e) => setTenant(e.target.value)} className="p-inputtext-lg p-d-block round" placeholder="Nombre de su agencia" />

                    <span style={{ fontWeight: '400', color:'#D84315', fontSize: '20px', marginTop: '15px', marginBottom: '15px' }}>Ingrese el código de su evento</span>

                    <VerificationInput
                        validChars={'A-Za-z0-9'}
                        removeDefaultStyles
                        value={code}
                        onChange={(e) => setCode(e.toUpperCase())}
                        classNames={{
                            container: Styles.container,
                            character: Styles.character,
                            characterInactive: "code-text-character--inactive",
                            characterSelected: Styles.characterSelected,
                        }} />

                    <Button label="Ingresar" type="submit" onClick={handleSearchEvent} style={{ borderRadius: 33, backgroundColor: '#F4511E', padding: 10, fontSize: 16, border: 'none', width: 230, marginTop: '20px' }} />
                </div >
            </form>
        </div>
    )
}
