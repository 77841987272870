export default {
    formId: 'medicalRecordForm',
    formField: {
        firstname: {
            name: 'firstname',
            label: 'Nombre*',
            type: 'text',
            requiredErrorMsg: 'Campo obligatorio'
        },
        lastname: {
            name: 'lastname',
            label: 'Apellido*',
            type: 'text',
            requiredErrorMsg: 'Campo obligatorio'
        },
        identification: {
            name: 'identification',
            label: 'Identificación*',
            type: 'text',
            requiredErrorMsg: 'Campo obligatorio'
        },
        bloodType: {
            name: 'bloodType',
            label: 'Grupo Sanguíneo*',
            type: 'text',
            requiredErrorMsg: 'Campo obligatorio'
        },
        bloodFactor: {
            name: 'bloodFactor',
            label: 'Factor Sanguíneo*',
            type: 'text'
        },
        address: {
            name: 'address',
            label: 'Dirección*',
            type: 'text',
            requiredErrorMsg: 'Campo obligatorio'
        },
        birthday: {
            name: 'birthday',
            label: 'Fecha de Nacimiento*',
            type: 'date',
            requiredErrorMsg: 'Campo obligatorio'
        },
        cellphone: {
            name: 'cellphone',
            label: 'Celular*',
            type: 'text',
            requiredErrorMsg: 'Campo obligatorio'
        },
        city: {
            name: 'city',
            label: 'Ciudad*',
            type: 'text',
            requiredErrorMsg: 'Campo obligatorio',
        },
        email: {
            name: 'email',
            label: 'Email*',
            type: 'text',
            requiredErrorMsg: 'Campo obligatorio',
            invalidErrorMsg: 'Verifique el formato del correo electrónico'
        },
        tutorFirstname: {
            name: 'tutorFirstname',
            label: 'Nombre del Padre/Madre/Apoderado*',
            type: 'text',
            requiredErrorMsg: 'Campo obligatorio',
        },
        tutorLastname: {
            name: 'tutorLastname',
            label: 'Apellido del Padre/Madre/Apoderado*',
            type: 'text',
            requiredErrorMsg: 'Campo obligatorio',
        },
        tutorAddress: {
            name: 'tutorAddress',
            label: 'Dirección del Padre/Madre/Apoderado*',
            type: 'text',
            requiredErrorMsg: 'Campo obligatorio'
        },
        tutorCellphone: {
            name: 'tutorCellphone',
            label: 'Celular del Padre/Madre/Apoderado*',
            type: 'text',
            requiredErrorMsg: 'Campo obligatorio'
        },
        observations: {
            name: 'observations',
            label: 'Observaciones',
            type: 'text',
        },
        acceptAndConfirm: {
            name: 'acceptAndConfirm',
            label: 'Acepto y confirmo que la información proporcionada es correcta',
            type: 'check',
            requiredErrorMsg: 'Por favor confirme y acepte la ficha médica antes de continuar',
        }
    }
};