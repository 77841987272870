import React from 'react'
import AppRouter from 'routes/AppRouter'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import 'prismjs/themes/prism-coy.css'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import './layout/flags/flags.css'
import './layout/layout.scss'
import './App.scss'
import { addLocale } from 'primereact/api'


import AppContext from './components/AppContext'

export const App = () => {
    const [user, setUser] = React.useState({})
    const [configuration, setConfiguration] = React.useState({})
    const [headerTitle, setHeaderTitle] = React.useState('')

    const changeHeaderTitle = (title) => {
        setHeaderTitle(title)
    }

    const changeUser = (user) => {
        setUser(user)
    }

    const changeConfiguration = (configuration) => {
        setConfiguration(configuration)
    }

    const context = {
        user: user,
        changeUser: changeUser,
        headerTitle: headerTitle,
        changeHeaderTitle: changeHeaderTitle,
        configuration: configuration,
        changeConfiguration: changeConfiguration
    }

    // Traducción para los calendarios de Prime Faces
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    })

    return (
        <AppContext.Provider value={context}>
             <div style={{ height: 15, width:'100%', backgroundColor: '#eee', position:'fixed', top:0, zIndex:999999}}></div>
            <AppRouter />
        </AppContext.Provider>
    )
}
