import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "Welcome": "Welcome AccessBand",
            "DNI": "ID",
            "apellido_tutor": "Tutor Lastanme",
            "nombre_tutor": "Tutor Name",
            "direccion_tutor": "Tutor Address",
            "telefono_tutor": "Tutor Phone",
            "message_dni": "Insert ID number",
            "choose_file": "Select...",
            "upload_file": "Upload",
            "cancel": "Cancel",
        }
    },
    esAR: {
        translation: {
            "Welcome": "AccessBand",
            "DNI": "DNI",
            "apellido_tutor": "Apellido del tutor",
            "nombre_tutor": "Nombre del tutor",
            "direccion_tutor": "Dirección del Tutor",
            "telefono_tutor": "Teléfono del tutor",
            "message_dni": "Ingresa el DNI",
            "choose_file": "Seleccione...",
            "upload_file": "Subir",
            "cancel": "Cancelar",
        }
    },
    esCH: {
        translation: {
            "Welcome": "AccessBand",
            "DNI": "RUT",
            "apellido_tutor": "Apellido del Apoderado",
            "nombre_tutor": "Nombre del Apoderado",
            "direccion_tutor": "Dirección del Apoderado",
            "telefono_tutor": "Teléfono del Apoderado",
            "message_dni": "Ingresa el RUT",
            "choose_file": "Seleccione...",
            "upload_file": "Subir",
            "cancel": "Cancelar",
        }
    },
    prBR: {
        translation: {
            "Welcome": "bem-vindo",
            "DNI": "DNI",
            "apellido_tutor": "Apellido del tutor",
            "nombre_tutor": "Nombre del tutor",
            "direccion_tutor": "Dirección del Tutor",
            "telefono_tutor": "Teléfono del tutor",
            "message_dni": "Ingresa el DNI",
            "choose_file": "Selecione...",
            "upload_file": "Carregar",
            "cancel": "Cancelar",
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "esAR", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;