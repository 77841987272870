import React, { useState } from "react";
import { Button } from "primereact/button";
import { SelectEventScreen } from "pages/welcome/SelectEventScreen";
import Styles from "./WelcomeScreen.module.scss";
import { LoginScreen } from "pages/auth/LoginScreen";
import { LogoAccesBand } from "components/Logo";
import appstore from "assets/images/appstore.png";
import play from "assets/images/googleplay.png";

export const WelcomeScreen = () => {
    const [socialLoggin, setSocialLoggin] = useState(false);
    const [adminLoggin, setAdminLoggin] = useState(false);
    return (
        <div className={Styles.home}>
            <div className={Styles.copy}>
                <span style={{ fontFamily: "SF", color: "#333" }}>
                    Producto desarrollado y distribuído por
                    <a target="_blank" href="https://www.innovare.com.ar">
                        {" "}
                        Innovare Software SRL
                    </a>
                </span>
            </div>
            <div className={Styles.background}>
                <div className={Styles.elementsContainer}>
                    <div className={Styles.buttonsContainer}>
                        <LogoAccesBand size={"30"} />

                        <span style={{ marginTop: 37, fontSize: 20, fontWeight: "bold", color: "#222" }}>Seguimiento de eventos privados</span>
                        
                        {!socialLoggin && !adminLoggin && (
                            <>
                                <Button style={{ borderRadius: 33, backgroundColor: "#F4511E", padding: 10, fontSize: 16, fontWeight: "800", border: "none", width: 230, marginTop: "10px" }} className="p-button-secondary" label="Ingreso Agencia" onClick={() => setAdminLoggin(true)} />

                                <div className={Styles.app} style={{ paddingTop: "20px" }}>
                                    <hr />
                                    <span style={{ color: "#333", fontWeight: '700', color:'red' }}>PASAJEROS : Bajar la aplicación</span>
                                    <div className={Styles.appImg}>
                                        <a target="_blank" href="https://apps.apple.com/ar/app/accessband/id1585519650" rel="noopener noreferrer">
                                            <img src={appstore} />
                                        </a>

                                        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.accessbandrn">
                                            <img src={play} />
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <span style={{ fontFamily: "SF", color: "#F4511E" }}>Pida a su agencia de viajes preferida los accesos a la aplicación</span>
                                </div>
                            </>
                        )}

                        {socialLoggin && (
                            <>
                                <SelectEventScreen />
                                <Button style={{ borderRadius: 33, backgroundColor: "#FF7043", padding: 10, fontSize: 16, border: "none", width: 230, marginTop: "10px" }} label="Volver" onClick={() => setSocialLoggin(false)} />
                            </>
                        )}

                        {adminLoggin && (
                            <>
                                <LoginScreen />
                                <Button style={{ borderRadius: 33, backgroundColor: "#FF7043", padding: 10, fontSize: 16, border: "none", width: 230 }} label="Volver" onClick={() => setAdminLoggin(false)} />
                            </>
                        )}
                    </div>
                    <div className={Styles.image} />
                </div>
            </div>
        </div>
    );
};
