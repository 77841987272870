import { FullScreenLoader } from 'components/FullScreenLoader'
import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import AuthServices from 'api/Auth'
import AppContext from 'components/AppContext'
import { WelcomeScreen } from 'pages/welcome/WelcomeScreen'
import { SocialRouter } from './SocialRouter'
import MedicalRecord from 'pages/medical-record-form/MedicalRecord'
const Paperbase = React.lazy(() => import('paperbase/Paperbase'));

const AppRouter = () => {
    const [adminLogged, setAdminLogged] = React.useState(false)
    const [socialLogged, setSocialLogged] = React.useState(false)
    const [checking, setChecking] = React.useState(true)
    const context = React.useContext(AppContext)

    React.useEffect(() => {
        whoAmI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const whoAmI = async () => {
        setChecking(true)
        if (localStorage.getItem('token') === null) {
            setAdminLogged(false)
        } else {
            await AuthServices.whoAmI(context).then(response => {
                if (response.status === 200) {
                    setAdminLogged(true)
                } else {
                    AuthServices.logout()
                    setAdminLogged(false)
                }
            }, (error) => {
                AuthServices.logout()
                setAdminLogged(false)
            })
        }

        if (localStorage.getItem('eventCode') === null || localStorage.getItem('tenant-id') === null) {
            setSocialLogged(false)
        } else {
            setSocialLogged(true)
        }

        setChecking(false)
    }

    if (checking) {
        return <FullScreenLoader />
    } else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={adminLogged ? <Navigate to='/admin' /> : socialLogged ? <Navigate to='/social' /> : <WelcomeScreen />} />
                    <Route path='login/*' element={adminLogged ? <Navigate to='/admin' /> : socialLogged ? <Navigate to='/social' /> : <WelcomeScreen />} />
                    <Route
                        path='admin/*'
                        element={
                            adminLogged ?
                                <React.Suspense fallback={<>...</>}>
                                    <Paperbase />
                                </React.Suspense>
                                : <Navigate to='/login' />
                        }
                    />
                    <Route path='social/*' element={socialLogged ? <SocialRouter /> : <Navigate to='/login' />} />
                    <Route path='medicalRecord/:agency' element={<MedicalRecord />} />
                    <Route path='/*' element={<Navigate to='/' />} />
                </Routes>
            </BrowserRouter>
        )
    }

}

export default AppRouter
