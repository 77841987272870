import { Routes, Route, Navigate } from 'react-router-dom'
import React, { Suspense, lazy } from 'react'
import { SocialHeader } from 'pages/social/SocialHeader'
import { FullScreenLoader } from 'components/FullScreenLoader'

export const SocialRouter = () => {

    // TODO: Testear Lazy
    const Scroll = lazy(() => import('pages/social/Scroll'))
    const SocialMembersList = lazy(() => import('pages/social/SocialMembersList'))

    return (
        <div>
            <SocialHeader />
            <Suspense fallback={<FullScreenLoader />}>
                <Routes>
                    <Route path='/' element={<Scroll />} />
                    <Route path='/members' element={<SocialMembersList />} />
                    <Route path='/*' element={<Navigate to='/social' />} />
                </Routes>
            </Suspense>
        </div>
    )
}