import { FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material'
import React from 'react'
import { CheckboxField, InputField } from './formFields'
import { useTranslation } from 'react-i18next';

export default function ConfirmationForm(props) {
    const { t, i18n } = useTranslation();

    const {
        firstname,
        lastname,
        identification,
        bloodType,
        bloodFactor,
        address,
        birthday,
        cellphone,
        city,
        email,
        tutorAddress,
        tutorCellphone,
        tutorFirstname,
        tutorLastname,
        observations,
        questions,
    } = props.medicalData;

    //here we only process name an value from dynamic fields
    let dynamicalFields = [];
    if (props.medicalData.attributes && props.medicalData.attributes.length > 0) {
        console.log(props.medicalData.attributes)
        dynamicalFields = props.medicalData.attributes.map(item => {
            return ({ label: item.label, value: Object.entries(props.medicalData).find(it => it[0] === item.name)[1] })
        })
    }

    const {
        acceptAndConfirm
    } = props.formField;

    return (
        <div>
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={'Nombre'}
                            defaultValue={firstname}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={'Apellido'}
                            defaultValue={lastname}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={'Identificación/DNI'}
                            defaultValue={identification}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={'Tipo de sangre'}
                            defaultValue={bloodType}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    {i18n.language !== 'esCH' &&
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={'Factor'}
                                defaultValue={bloodFactor}
                                variant="filled"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={'Dirección'}
                            defaultValue={address}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={'Fehca de nacimiento'}
                            defaultValue={birthday}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={'Celular'}
                            defaultValue={cellphone}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={'Ciudad'}
                            defaultValue={city}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={'Email'}
                            defaultValue={email}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={t('nombre_tutor')}
                            defaultValue={tutorFirstname}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={t('apellido_tutor')}
                            defaultValue={tutorLastname}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={t('direccion_tutor')}
                            defaultValue={tutorAddress}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={t('telefono_tutor')}
                            defaultValue={tutorCellphone}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>


                    {dynamicalFields.length > 0 && dynamicalFields.map((field, idx) => {
                        return (
                            <Grid key={idx} item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={field.label}
                                    defaultValue={field.value}
                                    variant="filled"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                        )
                    })}


                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={'Observaciones'}
                            defaultValue={observations}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
            <Typography marginBottom={'10px'} component="h6" variant="h4" align="center">
                Datos de la ficha médica
            </Typography>
            <React.Fragment>
                {questions.map((question, index) => {

                    return (
                        <div key={index}>
                            <FormControlLabel disabled control={<Switch defaultChecked={question.answer === 'SI'} onChange={(e) => (e.target.checked ? question.answer = 'SI' : question.answer = 'NO')} />} label={question.question} />
                            <InputField disabled name={`questions.${index}.description`} placeholder={'No se especificaron indicaciones extra'} fullWidth />
                        </div>
                    )
                })}
            </React.Fragment>
            <React.Fragment>
                <Grid item xs={12}>
                    <CheckboxField name={acceptAndConfirm.name} label={acceptAndConfirm.label} />
                </Grid>
            </React.Fragment>
        </div>
    )
}