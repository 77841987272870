import { FormControlLabel, Switch } from '@mui/material'
import React from 'react'
import { InputField } from './formFields'

export default function DiseasesForm(props) {
    return (
        <div>
            <React.Fragment>
                {props.questions.map((question, index) => {

                    /* Para completar las respuestas que vienen vacias por defecto */
                    if (question.answer === '') {
                        question.answer = 'NO'
                    }

                    return (
                        <div key={index}>
                            <FormControlLabel control={<Switch defaultChecked={question.answer === 'SI'} onChange={(e) => (e.target.checked ? question.answer = 'SI' : question.answer = 'NO')} />} label={question.question} />
                            <InputField name={`questions.${index}.description`} placeholder={'Indicaciones extra respecto a este ítem'} fullWidth />
                        </div>
                    )
                })}
            </React.Fragment>
        </div>
    )
}
